import styled, { css } from "styled-components";

export const Container = styled.section`
  position: absolute;
  backdrop-filter: blur(3px);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: "Inter", sans-serif;
  font-size: 24px;
  color: #fff;

  background: rgb(248, 0, 50);
  background: linear-gradient(
    90deg,
    rgba(0, 25, 252, 0.662) 2%,
    rgba(248, 0, 50, 0.6589888649425287) 100%
  );

  opacity: 0;
  pointer-events: none;
  transform: translateY(50px);

  transition: 0.5s;

  .close {
    position: absolute;
    top: 1.5rem;
    right: 2rem;
    transition: 0.7s;
    transform: rotate(45deg);
    transition: 0.7s;
    cursor: pointer;
  }

  nav {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 2rem;
    transform: scale(0.7);
    transition: 0.7s;
  }

  li {
    list-style-type: none;
  }

  a {
    display: block;
    padding: 10px;
    color: #fff;
    text-decoration: none;
  }

  a:hover {
    color: #f5c324;
  }

  ${({ isVisible }) =>
    isVisible &&
    css`
      opacity: 1;
      pointer-events: auto;
      transform: translateY(0px);

      .close {
        transform: rotate(0deg);
      }

      nav {
        transform: scale(1);
      }
    `}
`;
