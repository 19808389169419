import iceFire from '../sounds/ice-fire.mp3' 
import letraA from '../sounds/A.mp3' 
import letraB from '../sounds/B.mp3'
import letraC from '../sounds/C.mp3'
import letraD from '../sounds/D.mp3'
import letraE from '../sounds/E.mp3'
import letraF from '../sounds/F.mp3'
import letraG from '../sounds/G.mp3'
import letraH from '../sounds/H.mp3'
import letraI from '../sounds/I.mp3'
import letraJ from '../sounds/J.mp3'
import letraK from '../sounds/K.mp3'
import letraL from '../sounds/L.mp3'
import letraM from '../sounds/M.mp3'
import letraN from '../sounds/N.mp3'
import letraO from '../sounds/O.mp3'
import letraP from '../sounds/P.mp3'
import letraQ from '../sounds/Q.mp3'
import letraR from '../sounds/R.mp3'
import letraS from '../sounds/S.mp3'
import letraT from '../sounds/T.mp3'
import letraU from '../sounds/U.mp3'
import letraV from '../sounds/V.mp3'
import letraX from '../sounds/X.mp3'
import letraW from '../sounds/W.mp3'
import letraY from '../sounds/Y.mp3'
import letraZ from '../sounds/Z.mp3'
import aviao from '../assets/images/images-letters/Aviao.png'
import bola from '../assets/images/images-letters/Bola.png'
import casa from '../assets/images/images-letters/Casa.png'
import dente from '../assets/images/images-letters/Dente.png'
import estrela from '../assets/images/images-letters/Estrela.png'
import fogo from '../assets/images/images-letters/Fogo.png'
import gato from '../assets/images/images-letters/Gato.png'
import hipopotamo from '../assets/images/images-letters/Hipopotamo.png'
import igreja from '../assets/images/images-letters/Igreja.png'
import janela from '../assets/images/images-letters/Janela.png'
import ketchup from '../assets/images/images-letters/Ketchup.png'
import lapis from '../assets/images/images-letters/Lapis.png'
import mao from '../assets/images/images-letters/Mao.png'
import navio from '../assets/images/images-letters/Navio.png'
import oculos from '../assets/images/images-letters/Oculos.png'
import pato from '../assets/images/images-letters/Pato.png'
import queijo from '../assets/images/images-letters/Queijo.png'
import relogio from '../assets/images/images-letters/Relogio.png'
import sofa from '../assets/images/images-letters/Sofa.png'
import tomate from '../assets/images/images-letters/Tomate.png'
import uva from '../assets/images/images-letters/Uva.png'
import vaca from '../assets/images/images-letters/Vaca.png'
import webcam from '../assets/images/images-letters/Webcam.png'
import xicara from '../assets/images/images-letters/Xicara.png'
import youtube from '../assets/images/images-letters/Youtube.png'
import zebra from '../assets/images/images-letters/Zebra.png'


export const alphabetData = [
  {
    id:1,
    letter: 'A',
    sound:letraA,
    images:{
      image1:aviao,
      image2:aviao
    }
  },
  {
    id:2,
    letter: 'B',
    sound:letraB,
    images:{
      image1:bola,
      image2:bola
    }
  },
  {
    id:3,
    letter: 'C',
    sound: letraC,
    images:{
      image1:casa,
      image2:casa
    }
  },
  {
    id:4,
    letter: 'D',
    sound: letraD,
    images:{
      image1:dente,
      image2:dente
    }
  },
  {
    id:5,
    letter: 'E',
    sound: letraE,
    images:{
      image1:estrela,
      image2:estrela
    }
  },
  {
    id:6,
    letter: 'F',
    sound:letraF,
    images:{
      image1:fogo,
      image2:fogo,
    }
  },
  {
    id:7,
    letter: 'G',
    sound:letraG,
    images:{
      image1:gato,
      image2:gato
    }
  },
  {
    id:8,
    letter: 'H',
    sound:letraH,
    images:{
      image1:hipopotamo,
      image2:hipopotamo
    }
  },
  {
    id:9,
    letter: 'I',
    sound:letraI,
    images:{
      image1:igreja,
      image2:igreja
    }
  },
  {
    id:10,
    letter: 'J',
    sound:letraJ,
    images:{
      image1:janela,
      image2:janela
    }
  },
  {
    id:11,
    letter: 'K',
    sound:letraK,
    images:{
      image1:ketchup,
      image2:ketchup
    }
  },
  {
    id:12,
    letter: 'L',
    sound:letraL,
    images:{
      image1:lapis,
      image2:lapis
    }
  },
  {
    id:13,
    letter: 'M',
    sound:letraM,
    images:{
      image1:mao,
      image2:mao
    }
  },
  {
    id:14,
    letter: 'N',
    sound:letraN,
    images:{
      image1:navio,
      image2:navio
    }
  },
  {
    id:15,
    letter: 'O',
    sound:letraO,
    images:{
      image1:oculos,
      image2:oculos
    }
  },
  {
    id:16,
    letter: 'P',
    sound:letraP,
    images:{
      image1:pato,
      image2:pato
    }
  },
  {
    id:17,
    letter: 'Q',
    sound:letraQ,
    images:{
      image1:queijo,
      image2:queijo
    }
  },
  {
    id:18,
    letter: 'R',
    sound:letraR,
    images:{
      image1:relogio,
      image2:relogio
    }
  },
  {
    id:19,
    letter: 'S',
    sound:letraS,
    images:{
      image1:sofa,
      image2:sofa
    }
  },
  {
    id:20,
    letter: 'T',
    sound:letraT,
    images:{
      image1:tomate,
      image2:tomate
    }
  },
  {
    id:21,
    letter: 'U',
    sound:letraU,
    images:{
      image1:uva,
      image2:uva
    }
  },
  {
    id:22,
    letter: 'V',
    sound:letraV,
    images:{
      image1:vaca,
      image2:vaca
    }
  },
  {
    id:23,
    letter: 'X',
    sound:letraX,
    images:{
      image1:xicara,
      image2:xicara
    }
  },
  {
    id:24,
    letter: 'W',
    sound:letraW,
    images:{
      image1:webcam,
      image2:webcam
    }
  },
  {
    id:25,
    letter: 'Y',
    sound: letraY,
    images:{
      image1:youtube,
      image2:youtube
    }
  },
  {
    id:26,
    letter: 'Z',
    sound: letraZ,
    images:{
      image1:zebra,
      image2:zebra
    }
  }
]