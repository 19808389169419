import "./style.scss"
import { alphabetData } from "../../../../data/data"
import { useState} from "react"
import TextFieldsIcon from '@mui/icons-material/TextFields';
import '../../../.././globalStyle.scss'
export const Alphabet = () => {
const [letterSize, setLettersize]  = useState(false) 

//  function soundLetter(){
//      audioRef.current.play();
//  } 

 function fontSize(){
   setLettersize(!letterSize)
 }

//  const [isPlaying, setIsPlaying] = useState(false);
//  const audio = new Audio('/ice-fire.mp3');

//  const togglePlay = () => {
//    if (isPlaying) {
//      audio.pause();
//    } else {
//      audio.play();
//    }
//    setIsPlaying(!isPlaying);
//  };

   const handlePlay = (sound) => {
     const audio = new Audio(sound)

     audio.play()
   }
 

  return(
         <div className="exploring-the-alphabet-container">
            <div>
               <div className="explore-the-alphabet-header">
                  <h1 className="phrase-alphabet"> CLIQUE EM UMA LETRA</h1>
                  <button onClick={fontSize} ><TextFieldsIcon/></button>
               </div>
               <div className="all-cards-letter-container">
               {alphabetData.map((letter) => (
               <button  className="alphabet-card-with-letter"
                        onClick={() => handlePlay(letter.sound)}>
                           {letterSize ? letter.letter.toLowerCase() : letter.letter.toUpperCase()}
               </button>))} 
            </div>
            </div>
         </div>
  )}