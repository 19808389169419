import { NavLink } from "react-router-dom";
import "./style.scss"
import imageHome from './image-home.png'
import elizeu from '../../assets/images/collaborators/elizeu.jpeg'
import carol from '../../assets/images/collaborators/carol.jpeg'
import karen from '../../assets/images/collaborators/karen.jpeg'
import luiza from '../../assets/images/collaborators/luiza.jpeg'
import Astronauta from './astronauta.gif'

export const Home = () => {
return(
  <>
  <div className="home-container">
    <div className="home-body-container">
      <div className="home-info-elements">
      <h1 className="home-title">ABC-GO</h1>
      <p className="home-text">
      Olá amiguinho!
      Aqui você encontrará várias aventuras com o mundo das letras. 
      Você vai encontrar planetas e monstrinhos em sua jornada e precisará explorar muito bem para aprender cada vez mais!
      Conto com você para completar a missão!
      </p>
      <button className="home-init-button"><NavLink to="/escolha-o-planeta">INICIAR</NavLink></button>
      </div>
      <img className="image-home" src={imageHome}/>
    </div>
    <div className="about-abc-go">
    <img src={Astronauta}/>
      <p className="about-abc-go-text">
      A escolha do tema e do contexto do protótipo adaptado da plataforma tecnológica
      “ABC-Go”, está fundamentada no imaginário infantil, apresentada na forma de cenário com
      foguetes e planetas e na aprendizagem sem erro. Essa escolha foi articuladamente pensada e
      estruturada pela dissertação, por possibilitar ampla exploração a fim de
      atender aos objetivos educativos da alfabetização e letramento a partir do desenvolvimento da
      consciência fonológica.
      </p>
   
    </div>
    <div className="collaborators">
      <div className="person-collaborator-container">
        <img className="person-collaborator" src={elizeu}/>
        <p className="person-collaborator-text">
          Professor Orientador Elizeu Coutinho 
        </p>
      </div>
      <div className="person-collaborator-container" >
      <img className="person-collaborator" src={carol}/>
        <p className="person-collaborator-text">
          Carolina Yoshida idealizadora do protótipo
        </p>
      </div>
      <div className="person-collaborator-container">
      <img className="person-collaborator" src={karen}/>
        <p className="person-collaborator-text">
          Estudante de psicologia
        </p>
      </div>
      <div className="person-collaborator-container">
      <img className="person-collaborator" src={luiza}/>
        <p className="person-collaborator-text">
          Estudante de psicologia e desenvovolvedora
        </p>
      </div>
      

    </div>
  </div>

  </>
)}