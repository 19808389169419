import { createContext, useReducer } from "react";


const STAGES = [ 'ChooseLevel', 'ChooseLetter', 'Playing','NextLevel', 'Reinforcement', 'End']
const LEVELS = [ 'level1', 'level2', 'level3', 'level4']

const initialState = {
  gameStage: STAGES[0],
  levelOfGame:[0],
}

const GlobalReducer = (state, action) => {
console.log(action, state)

    switch(action.type){
      case "SET_LEVEL":
        return{
          ...state,
          gameStage: STAGES[1],
        }

        case "SET_LETTER":
          return{
            ...state,
            gameStage:STAGES[2],
          }

        case "RESTART":
          return{
            ...state,
          gameStage:STAGES[0],
          levelOfGame:[0]
          }

        case "PLAY_GAME":
          return{
            ...state,
          }

        case "NEXT_LEVEL":
          return{
            ...state,
            gameStage:STAGES[3]
          }

        case "REINFORCEMENT":
          return {
            ...state,
            gameStage:STAGES[4],
          }

        case "END":
          return{
            ...state,
            gameStage:STAGES[5]
          }

          
        default:
          return state
    }
}


export const GlobalContext = createContext({})

export const GlobalProvider = (props) => {
    const value = useReducer(GlobalReducer, initialState)

    return <GlobalContext.Provider value={value}>{props.children}</GlobalContext.Provider>
}
