export const cards = [
  {
    id: '1',
    back: 'A',
  },
  {
    id: '2',
    back: 'B',
  },
  {
    id: '3',
    back: 'C',
  },
  {
    id: '4',
    back: 'D',
  },
  {
    id: '5',
    back: 'E',
  },
  {
    id: '6',
    back: 'F',
  },
  {
    id: '7',
    back: 'G',
  },
  {
    id: '8',
    back: 'H',
  },
  {
    id: '9',
    back: 'I',
  },
  {
    id: '10',
    back: 'J',
  },
  {
    id: '11',
    back: 'K',
  },
  {
    id: '12',
    back: 'L',
  },
  {
    id: '13',
    back: 'M',
  },
  {
    id: '14',
    back: 'N',
  },
  {
    id: '15',
    back: 'O',
  },
  {
    id: '16',
    back: 'P',
  },
  {
    id: '17',
    back: 'Q',
  },
  {
    id: '18',
    back: 'R',
  },
  {
    id: '19',
    back: 'S',
  },
  {
    id: '20',
    back: 'T',
  },
  {
    id: '21',
    back: 'U',
  },
  {
    id: '22',
    back: 'V',
  },
  {
    id: '23',
    back: 'X',
  },
  {
    id: '24',
    back: 'W',
  },
  {
    id: '25',
    back: 'Y',
  },
  {
    id: '26',
    back: 'Z',
  },


];
