import "./style.scss"

export const SignIn = () => {
  return(
    <div className="signup-container">
    
        <div className="inputs-container">
        <h1 className="title">Cadastre-se</h1>
      <div className="home-body">
      <div className="home-left-side">
                <p> Nome completo </p>
                <input  className="into-input"type="text" ></input>

                  <p> Escolha uma senha</p>
                <input className="into-input" type="text" ></input>
            </div>
    
            <div className="home-right-side">
                <p>  Digite seu e-mail</p>
                    <input className="into-input" type="text" ></input>
                <p> Confirme a senha</p>
                    <input className="into-input" type="text" ></input>
            </div>  
      </div>
            
            <button> Enviar </button> 
        </div>
        
   </div>
  )
}