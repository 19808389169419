import './style.scss'
import { Grid } from './elements/Grid'
import { cards } from '../../../../data/cards'
import { GlobalContext } from "../../../../context/globalReducer"
import { useContext, useState, useEffect } from 'react'
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import Parabens from '../../../../assets/images/parabens.gif'


import Joinha from '../../../../assets/images/joinha.gif'
export const MemoryGame = () => {

  useEffect(() => {
    dispatch({type:"RESTART"})
  }, [])


  const [stateGame, dispatch] = useContext(GlobalContext)
  const [dataLevel, setDataLevel] = useState('');
  const [ dataLetter, setDataLetter] = useState('');
  
  function processGame(dataLevel){

    if(dataLevel === 1){
      const  levelOfGame = [cards[parseInt(Math.random() * 26)]]
      return levelOfGame
    }if(dataLevel === 2){
      const  levelOfGame = [cards[parseInt(Math.random() * 26)],
        cards[parseInt(Math.random() * 26)]]
        return levelOfGame
    }if(dataLevel === 3){
      const  levelOfGame = [cards[parseInt(Math.random() * 26)],
        cards[parseInt(Math.random() * 26)],
        cards[parseInt(Math.random() * 26)]]
        return levelOfGame
    }else{
      const levelOfGame = [cards[parseInt(Math.random() * 26)],
        cards[parseInt(Math.random() * 26)],
        cards[parseInt(Math.random() * 26)],
        cards[parseInt(Math.random() * 26)]]
        return levelOfGame
    }
  }

  const game = processGame(dataLevel)
  const levels = [ 1, 2, 3, 4 ]

  function getLevel(item, data){
    return (
        dispatch({type: "SET_LETTER"}),
        setDataLevel(item)
    )
  }
  
  function forNextLevel(){
      dispatch({type: "SET_LETTER"})
      if(dataLevel <= 3){
        setDataLevel(dataLevel + 1)
      } else{
        dispatch({type: "END"})
      }

  }

  return(
    <>
    <div className="memory-game-container">
      <div className="cards-memory-game-container">
        {stateGame.gameStage === "ChooseLevel" && 
                  <div className="memory-game-elements-game">
                    <p className="set-level-title"> Escolha um nível de dificuldade</p>
                    <div className="all-buttons-level">
                      {levels.map ((item) =>(
                        <button id={item} onClick={() => getLevel(item)} className="button-level">{item}</button>
                      ) )}
                  </div>
                </div>}
          {stateGame.gameStage === "Playing" && <Grid className="memory-game" cards={game}/>}
          {stateGame.gameStage === "Reinforcement" && 
            <div className="reiforcement-for-next-level">
            <img src={Joinha}/>
            <button className="next-level-button" onClick={forNextLevel}>PRÓXIMO NÍVEL</button>
            </div>}
          {stateGame.gameStage === "NextLevel" &&
          <div>
          <p className="set-level-title"> Escolha um nível de dificuldade</p>
          <div className="all-buttons-level">
            {levels.map ((item) =>(
              <button id={item} onClick={() => getLevel(item)} className="button-level">{item}</button>
            ) )}
        </div>
      </div>}

      {stateGame.gameStage === "End" &&
    <div className="parabens-container">
      <h1>PARABÉNS! VOCÊ CONSEGUIU ESSE DESAFIO!</h1>
        <img alt="parabens" src={Parabens}/>
    </div> }
      </div>
    </div>
    </>
  )
}