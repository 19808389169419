import { alphabetData } from "../../data/data"
import './setLetter.scss'
import { useContext } from "react"
import { GlobalContext } from "../../context/globalReducer";
import PhonemeIsolationContext from "../../context/configGameContext";
import { MemoryGame } from "../../pages/game/planet-1/memory-game/MemoryGame";

export const SetLetter = () =>{
  const [stategame, dispatch] = useContext(GlobalContext);
  const { dataLetter, setDataLetter } = useContext(PhonemeIsolationContext);

  function getLetter(id, data){
    return (
     
      dispatch({type: "SET_LETTER"}),
      setDataLetter(id)
      )}
  
  return(
   <div className="set-letter-container">
    <p className="set-letter-title">Escolha uma letra</p>
     <div  className="all-set-letters">
        {alphabetData.map((letter) => (<button 
            id={letter.letter}
            onClick={() => getLetter(letter.letter)} 
            className="each-set-letter">{letter.letter}</button>))}
    </div>
   </div>
      )
}
