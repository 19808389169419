import { NavLink } from "react-router-dom";
import { useEffect } from "react";
import { Container } from "./styled";
import close from "./assets/close.svg";

export function MenuMobile({ menuIsVisible, setMenuIsVisible }) {
  useEffect(() => {
    document.body.style.overflowY = menuIsVisible ? "hidden" : "auto";
  }, [menuIsVisible]);

  return (
    <Container isVisible={menuIsVisible}>
      <img
        className="close"
        src={close}
        onClick={() => setMenuIsVisible(false)}
      />
      <nav>
        <li>
          <NavLink to="/">Home</NavLink>
        </li>
        <li>
          <NavLink to="/login">Login</NavLink>
        </li>
        <li>
          <NavLink to="/register">Cadastre-se</NavLink>
        </li>
      </nav>
    </Container>
  );
}
