import "./style.scss"

export const Login = () => {
  return(
    <>
    <div className="login-elements">
    <div className="login-container">
        <h1 id="title-login"> Login </h1>
        <div datas-container>
            <p>E-mail</p>
            <input className="input-login" type="text"></input>
            <div className="password">
                    <p>Senha</p>
                    <input className="input-login"  type="text"></input>
            </div> 
        </div>
        <button className="button-access"> Entrar </button>
     </div>
    
    </div>
    
    </>  
   
)}