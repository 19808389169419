import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useState } from "react";
import { Header } from "./components/header/Header";
import { Footer } from "./components/footer/Footer";
import  { Home }   from "./pages/home/Home.js";
import { MenuMobile } from "./components/menuMobile/MenuMobile";
import { Login } from "./pages/login/Login";
import { Alphabet } from "./pages/game/planet-1/explore-the-alphabet/Alphabet";
import { ChooseActivityPlanet1 } from "./pages/game/planet-1/choose-activity/ChooseActivityPlanet1"
import { KnowingTheSounds } from "./pages/game/planet-1/knowing-the-sounds/KnowingTheSounds"
import { MemoryGame } from "./pages/game/planet-1/memory-game/MemoryGame"
import  PhonemeIsolation  from "./pages/game/planet-2/PhonemeIsolation"
import { PhonemeIdentification  } from "./pages/game/planet-3/PhonemeIdentification"
import { SignIn } from "./pages/signIn/signIn"
import { ChooseThePlanet } from "./pages/game/start/ChooseThePlanet";

function App() {
  const [menuIsVisible, setMenuIsVisible] = useState(false);


  return (
    <BrowserRouter>
    <MenuMobile
      menuIsVisible={menuIsVisible}
      setMenuIsVisible={setMenuIsVisible}
    />
    <Header setMenuIsVisible={setMenuIsVisible} />
    <Routes>
      <Route path="/" element={<Home/>}></Route>
      <Route path="/login" element={<Login/>}></Route>
      <Route path="/register" element={<SignIn/>}></Route>
      <Route path="/escolha-o-planeta" element={<ChooseThePlanet/>}></Route>
      <Route path="/planeta1" element={<ChooseActivityPlanet1/>}></Route>
      <Route path="/planeta1/explorando-o-alfabeto" element={<Alphabet/>}></Route>
      <Route path="/planeta1/conhecendo-os-sons" element={<KnowingTheSounds/>}></Route>
      <Route path="/planeta1/jogo-da-memoria" element={<MemoryGame/>}></Route>
      <Route path="/planeta2" element={<PhonemeIsolation/>
}>
      </Route>
     
      <Route path="/planeta3" element={<PhonemeIdentification />}></Route>
      {/* <Route path="/planeta4" element={<PhonemeCategorization/>}></Route> */}
    </Routes>
    <Footer />
  </BrowserRouter>
  )
}

export default App;
