export const ActivityCard = ({nome}) => {
  return(
    <>
      <div>
        <h1>
          {nome}
        </h1>
      </div>
    </>
  )
}