import { useContext } from "react"
import { GlobalContext } from "../../context/globalReducer";
import PhonemeIsolationContext from "../../context/configGameContext";
import './style.scss'

export const SetLevel = () => {
  const [stategame, dispatch] = useContext(GlobalContext);
  const { dataLevel, setDataLevel } = useContext(PhonemeIsolationContext) 

 function getLevel(item, data){
  return (
    dispatch({type: "SET_LEVEL"}), 
    setDataLevel(item)
    )}
  
const levels = [ 1, 2, 3, 4 ]

  return (
    <div className="set-level-container">
      <p className="set-level-title"> Escolha um nível</p>
      <div className="all-buttons-level">
        {levels.map ((item) =>(
          <button id={item} onClick={() => getLevel(item)} className="button-level">{item}</button>
        ) )}
      </div>
    </div>
    
  )
}
