import './style.scss';
import { alphabetData } from '../../../../data/data';
import { useState, useEffect } from 'react';
import { GlobalContext } from "../../../../context/globalReducer"
import { useContext } from 'react';
import { SetLevel } from "../../../../components/setLevel/SetLevel"
import { SetLetter } from "../../../../components/setLetter/SetLetter"
import configGameContext from "../../../../context/configGameContext"
import Joinha from '../../../../assets/images/joinha.gif'
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import Sound from './sound.png'
import Parabens from '../../../../assets/images/parabens.gif'
export const KnowingTheSounds = () => {
  
  useEffect(() => {
    dispatch({type:"RESTART"})
  }, [])


  const [stateGame, dispatch] = useContext(GlobalContext)
  const [dataLevel, setDataLevel] = useState('');
  const [dataLetter, setDataLetter] = useState('');
  const [imageCx, setImageCx] = useState(undefined)

  var letterOfGame = alphabetData.filter(letra => letra.letter === dataLetter)
  const letterChoosed = letterOfGame.map(item => item.letter)
  function processGame(dataLevel){

    if(dataLevel === 1){
      const  levelOfGame = [letterChoosed]
      return levelOfGame
    }if(dataLevel === 2){
      const  levelOfGame = [letterChoosed[0],
        alphabetData[parseInt(Math.random() * 26)].letter]
        return levelOfGame
    }if(dataLevel === 3){
      const  levelOfGame = [letterChoosed[0],
        alphabetData[parseInt(Math.random() * 26)].letter,
        alphabetData[parseInt(Math.random() * 26)].letter]
        return levelOfGame
    }else{
      const levelOfGame = [letterChoosed[0],
        alphabetData[parseInt(Math.random() * 26)].letter,
        alphabetData[parseInt(Math.random() * 26)].letter,
        alphabetData[parseInt(Math.random() * 26)].letter]
        return levelOfGame
    }
  }

useEffect(() => {
  
})

  const reoderingLetters = processGame(dataLevel).sort(() => {
    return Math.random() - 0.5;
  })


// const [reoderingLetters, setReoderingLetters] = useState();

// useEffect(() => {
//   const reorderedArray = processGame(dataLevel).sort(() => {
//     return Math.random() - 0.5;
//   }, [dispatch]);

//   setReoderingLetters(reorderedArray);
// });

  const [contador, setContador] = useState(0)
  function WinOrLose(letterClicked){
    const LetterFilter = alphabetData.filter(letra => letra.letter === letterClicked )
    const soundGame = LetterFilter.map(teste => teste.sound)
    const audio = new Audio(soundGame)
    audio.play()


   setTimeout(() => {
    setContador(contador + 1)
    if(letterClicked == letterOfGame[0].letter && dataLevel === 1){
      dispatch({type: 'REINFORCEMENT'})
    }
    if(letterClicked == letterOfGame[0].letter && contador < 3){
      return (
        dispatch({type: "PLAY_GAME"}))
    }if(letterClicked == letterOfGame[0].letter && contador >= 3){
      setContador(0)
      return(
        dispatch({type: 'REINFORCEMENT'})
      )
    }else{ 
      return (console.log('não deu certo'), 
      setImageCx(true))
    } 
   }, 1000);

  }

  function forNextLevel(){
    if(dataLevel <= 3){
      dispatch({type: "NEXT_LEVEL"})
      setDataLevel(dataLevel + 1)
    }else if(dataLevel === 4){
      dispatch({type: "END"})
    }
  }

  function restart() {
    dispatch({type: "RESTART"})
  }

function audioLetterOfGame(){
  const soundChoosedGame = letterOfGame.map(item => item.sound)
  const audio = new Audio(soundChoosedGame)
    audio.play()
}


  return(
    <>
    <div className="knowing-the-sounds-container">
    {stateGame.gameStage === "ChooseLevel" && <configGameContext.Provider value={{dataLevel, setDataLevel}}>
                                                <SetLevel />
                                              </configGameContext.Provider>}
    {stateGame.gameStage === "ChooseLetter" && <configGameContext.Provider value={{dataLetter, setDataLetter}}>
                                                  <SetLetter /> 
                                              </configGameContext.Provider>}
    {stateGame.gameStage === "Playing" && 
    <div className="game-1-container">
      <button className="restart-button"onClick={restart}><RestartAltIcon/></button>
       <div className="game-space-game-1">
            <div className="mascot-planet-1-container">
                <p>QUAL O SOM DA LETRA: </p>
                <button className="button-sound-icon" onClick={audioLetterOfGame}>
                   <img className='image-sound' alt="sound" src={Sound}/>
                </button>
            </div>
        <div className="knowing-the-sounds-elements-game">
          <div >{reoderingLetters.map(
                             item => <button className="knowing-the-sounds-letters"
                                             onClick={() => {WinOrLose(item)}}>{item}
                                             {console.log(item)}
                                      </button>)}</div>
                                    
        </div>
       </div>
    </div>}

    {stateGame.gameStage === "Reinforcement" && 
              <div className="reiforcement-for-next-level">
                <img src={Joinha}/>
                <button className="next-level-button" onClick={forNextLevel}>PRÓXIMO NÍVEL</button>
              </div>}
    {stateGame.gameStage === "NextLevel" &&
        <div className="game-1-container">
        <button className="restart-button"onClick={restart}><RestartAltIcon/></button>
         <div className="game-space-game-1">
              <div className="mascot-planet-1-container">
                  <p>QUAL O SOM DA LETRA: </p>
                  <button className="button-sound-icon" onClick={audioLetterOfGame}>
                     <img className='image-sound' alt="sound" src={Sound}/>
                  </button>
              </div>
          <div className="knowing-the-sounds-elements-game">
            <div >{reoderingLetters.map(
                               item => <button className="knowing-the-sounds-letters"
                                               onClick={() => {WinOrLose(item)}}>{item}
                                               {console.log(item)}
                                        </button>)}</div>
                                      
          </div>
         </div>
      </div>
      }
      {stateGame.gameStage === "End" &&
    <div className="parabens-container">
      <h1>PARABÉNS! VOCÊ CONSEGUIU ESSE DESAFIO!</h1>
        <img alt="parabens" src={Parabens}/>
    </div> }
      </div>
    </>
  )
}

