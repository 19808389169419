import './style.scss'
import { SetLevel } from '../../../components/setLevel/SetLevel'
import { SetLetter } from '../../../components/setLetter/SetLetter'
import { useState, useContext, useEffect } from 'react'
import { GlobalContext } from "../../../context/globalReducer"
import { alphabetData } from '../../../data/data'
import configGameContext from '../../../context/configGameContext'
import Joinha from '../../../assets/images/joinha.gif'
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import Parabens from '../../../assets/images/parabens.gif'
export const PhonemeIdentification = () => {

  useEffect(() => {
    dispatch({type:"RESTART"})
  }, [])


  const [stateGame, dispatch] = useContext(GlobalContext);
  const [dataLevel, setDataLevel] = useState('');
  const [ dataLetter, setDataLetter] = useState('');
  const [imageCx, setImageCx] = useState(undefined)

  var letterOfGame = alphabetData.filter(letra => letra.letter === dataLetter)
  const letterChoosed = letterOfGame.map(item => item.letter)
  const ImageOfLetterChoosed = letterOfGame.map(item => item.images.image1)

  function processGame(dataLevel){

    if(dataLevel === 1){
      const  levelOfGame = [letterChoosed]
      return levelOfGame
    }if(dataLevel === 2){
      const  levelOfGame = [letterChoosed,
        alphabetData[parseInt(Math.random() * 26)].letter]
        return levelOfGame
    }if(dataLevel === 3){
      const  levelOfGame = [letterChoosed,
        alphabetData[parseInt(Math.random() * 26)].letter,
        alphabetData[parseInt(Math.random() * 26)].letter]
        return levelOfGame
    }else{
      const levelOfGame = [letterChoosed,
        alphabetData[parseInt(Math.random() * 26)].letter,
        alphabetData[parseInt(Math.random() * 26)].letter,
        alphabetData[parseInt(Math.random() * 26)].letter]
        return levelOfGame
    }
  }

  const reoderingSounds = processGame(dataLevel).sort(() => {
    return Math.random() - 0.5;
  })

  // const soundGame = letterOfGame.map(item => item.sound)

  const handlePlay = (letterOfClick) => {
    var soundClick = alphabetData.filter(item => item.letter == letterOfClick)
    const soundChoosed = soundClick.map(item => item.sound)
    const audio = new Audio(soundChoosed)
    WinOrLose(letterOfClick)
   audio.play()
 }
 
 function restart() {
  dispatch({type: "RESTART"})
}

function WinOrLose(letter){
  setTimeout(() => {
    if(letter == letterChoosed){
      dispatch({type: "REINFORCEMENT"})
    }else{
      console.log('não')
    }
  }, 2000);
}

function forNextLevel(){
  dispatch({type: "NEXT_LEVEL"})
  if(dataLevel <= 3){
    setDataLevel(dataLevel + 1)
  } else{
    dispatch({type: "END"})
  }
}

  return (
    <div className="phoneme-identification-game-container">
   {stateGame.gameStage === "ChooseLevel" && <configGameContext.Provider value={{dataLevel, setDataLevel}}>
                                                <SetLevel />
                                              </configGameContext.Provider>}
    {stateGame.gameStage === "ChooseLetter" && <configGameContext.Provider value={{dataLetter, setDataLetter}}>
                                                  <SetLetter /> 
                                              </configGameContext.Provider>}
   {stateGame.gameStage === "Playing" && 
                      <div className="phoneme-identification-elements-container">
                      <button className="phoneme-identification-restart-button"onClick={restart}><RestartAltIcon/></button>
                      <div className="mascot-planet-3"> 
                        <h1 className="mascot-message-3"> COM QUAL SOM ESTA IMAGEM COMEÇA?</h1>
                      </div>
                        <img className="images-planet-3" src={ImageOfLetterChoosed} alt="gatinho"/>
                      <div className="phoneme-identification-button-sound">
                      {reoderingSounds.map( item => <button className="phoneme-identification-letters" onClick={() => handlePlay(item)}>{item}</button>)}
                      </div>
             </div>
             }

    {stateGame.gameStage === "Reinforcement" && 
   <div className="reiforcement-for-next-level">
   <img src={Joinha}/>
   <button className="next-level-button" onClick={forNextLevel}>PRÓXIMO NÍVEL</button>
 </div>}

    {stateGame.gameStage === "NextLevel" &&
          <div className="phoneme-identification-elements-container">
          <button className="phoneme-identification-restart-button"onClick={restart}><RestartAltIcon/></button>
          <div className="mascot-planet-3"> 
            <h1 className="mascot-message-3"> COM QUAL SOM ESTA IMAGEM COMEÇA?</h1>
          </div>
            <img className="images-planet-3" src={ImageOfLetterChoosed} alt="gatinho"/>
          <div className="phoneme-identification-button-sound">
          {reoderingSounds.map( item => <button className="phoneme-identification-letters" onClick={() => handlePlay(item)}>{item}</button>)}
          </div>
 </div>
    }

    {stateGame.gameStage === "End" &&
    <div className="parabens-container">
      <h1>PARABÉNS! VOCÊ CONSEGUIU ESSE DESAFIO!</h1>
        <img alt="parabens" src={Parabens}/>
    </div> }
    </div>
  
  )
}
