import './style.scss';
import { MemoryCards } from '../memory-cards/MemoryCards';
import { useRef, useState, useContext } from 'react';
import { duplicateRegenerateSortArray } from '../../../../../utils/card-utils';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { GlobalContext } from '../../../../../context/globalReducer';

export function Grid({cards}) {

  const [stateCards, setStateCards] = useState(() => {
    return duplicateRegenerateSortArray(cards);
  });
  const first = useRef(null);
  const second = useRef(null);
  const unflip = useRef(false);
  const [matches, setMatches] = useState(0);
  const [moves, setMoves] = useState(0);
  const [stateGame, dispatch] = useContext(GlobalContext)

  const handleReset = () => {
    setStateCards(duplicateRegenerateSortArray(cards));
    first.current = null;
    second.current = null;
    unflip.current = false;
    setMatches(0);
    setMoves(0);
  };

  const handleClick = (id) => {
    const newStateCards = stateCards.map((card) => {
      // Se o id do cartão não for o id clicado, não faz nada
      if (card.id !== id) return card;
      // Se o cartão já estiver virado, não faz nada
      if (card.flipped) return card;

      // Desviro possíveis cartas erradas
      if (unflip.current && first.current && second.current) {
        first.current.flipped = false;
        second.current.flipped = false;
        first.current = null;
        second.current = null;
        unflip.current = false;
      }

      // Virar o card
      card.flipped = true;

      // Configura primeiro e segundo cartão clicados
      if (first.current === null) {
        first.current = card;
      } else if (second.current === null) {
        second.current = card;
      }

      // Se eu tenho os dois cartão virados
      // Posso checar se estão corretos
      if (first.current && second.current) {
        if (first.current.back === second.current.back) {
          // A pessoa acertou
          first.current = null;
          second.current = null;
          setMatches((m) => m + 1);
        } else {
          // A pessoa errou
          unflip.current = true;
        }

        setMoves((m) => m + 1);
      }

      return card;
    });

    setStateCards(newStateCards);
  };

const cardsArrayLength = stateCards.length
   
if(cardsArrayLength === matches*2){
  setTimeout(() => {
    dispatch({type:'REINFORCEMENT'})
  }, 1000);
}

function restart() {
  dispatch({type: "RESTART"})
}
  return (
    <>
    <div className="memory-game-container-grid">
    <button className="memory-game-restart-button"onClick={restart}><RestartAltIcon/></button>
    <div className="text">
        <div className="scoreboard">
             <p>Movimentos: {moves} | Combinações: {matches} | {' '} </p>
        </div>
      </div>
      <div className="grid">
        {stateCards.map((card) => {
          return <MemoryCards {...card} key={card.id} handleClick={handleClick} />;
        })}
      </div>
    </div>
    </>

  );
}
