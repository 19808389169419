import "./style.scss"
import { ActivityCard } from "../../../../components/choose-activity-card/ActivityCard"
import { NavLink } from "react-router-dom";
import Slider from 'react-slick'

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Planet1 from '../../../../assets/images/planeta-1.svg'
import Planet2 from '../../../../assets/images/planeta-2.svg'
import Planet3 from '../../../../assets/images/planeta-3.svg'
import ConhecendoSons from './conhecendo-sons.png'
import ExplorandoAlfabeto from './explorando.png'
import JogoDaMemoria from './jogo-memoria.png'
export const ChooseActivityPlanet1 = () => {

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    autoplay: true,
  };

  const teste = [
   {image1: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS1CWUb0v_jr8BhbZ2r7DKxjrEK8LXw8Xvf2Q&usqp=CAU'},
    {image2: 'https://i0.wp.com/www.brinquedosdavila.com.br/wp-content/uploads/2022/02/placa-do-alfabeto-em-madeira-com-letras-e-palavras.jpg?fit=1500%2C1500&ssl=1'},
    {image3: 'https://images.tcdn.com.br/img/img_prod/334771/tapete_jogo_da_velha_grande_589_1_20190529163314.jpg'},
  ]
  


  return(
    <>
      <div className="container-all-activitys">
        <h1 className="choose-activity-title">Saudações, viajante! Escolha qual será sua primeira aventura</h1>
        <div className="container-carousel">
        <Slider className="slider-carousel" {...settings}>
        <button className="choose-activity-button">
          <NavLink className="link" to="/planeta1/explorando-o-alfabeto">
            <p className="title-of-planet">Explorando o Alfabeto</p>
            <img className="planet-img" alt="planeta3" src={ExplorandoAlfabeto}/>
          </NavLink>
        </button>
        <button className="choose-activity-button">
          <NavLink className="link" to="/planeta1/conhecendo-os-sons">
            <p  className="title-of-planet">Conhecendo os Sons</p>
            <img  className="planet-img" alt="planeta2" src={ConhecendoSons}/>
          </NavLink> 
        </button>
        <button className="choose-activity-button">
          <NavLink className="link" to="/planeta1/jogo-da-memoria">
          <p  className="title-of-planet">Jogo da memória</p>
            <img className="planet-img" alt="planeta1" src={JogoDaMemoria}/>
          </NavLink> 
        </button>
        </Slider>
        </div>
      </div>
    </>

  )
}