import styled from "styled-components";

export const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  padding: 25px;
  background-color: #f1f2f2;
  
  .active {
    color: #f5c324;
  }

  .menuContent {
    display: none;
    justify-content: end;
    align-items: center;
    padding-right: 10px;
    cursor: pointer;

    @media (max-width: 900px) {
      display: flex;
      align-items: center;
    }
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: "Roboto", sans-serif;
  font-size: 20px;
  color: rgb(14, 11, 62);

  h2 {
    margin-right: 30px;
  }

  @media screen and (max-width: 1440px) {
    font-size: 0.9375rem;
  }

  @media screen and (max-width: 1024px) {
    font-size: 0.75rem;
  }
`;

export const NavBar = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 1rem;
  flex-wrap: wrap;

  font-family: "Inter", sans-serif;
  font-size: 24px;
  color: #fff;
  

  li {
    display: inline;
    cursor: pointer;
  }

  a {
    display: block;
    padding: 10px;
    text-decoration: none;

    color: rgb(14, 11, 62);
  }

  a:hover {
    color: #f5c324;
  }

  @media screen and (max-width: 1440px) {
    font-size: 1.125rem;
  }

  @media screen and (max-width: 1024px) {
    font-size: 1rem;
  }

  @media (max-width: 900px) {
    display: ${({ show }) => (show ? "block" : "none")};

    position: fixed;
    width: 100%;
    height: 100%;
    padding: 0;

    li {
      width: 100%;
    }

    a:hover {
      cursor: pointer;
      color: #f5c324;
    }
  }
`;
