import styled from "styled-components";

export const FooterContainer = styled.footer`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 32px 0px;
  background-color: #f1f2f2;

  
  h1 {
    color: #626060;
    font-family:Arial, Helvetica, sans-serif;
    font-size:18px;
  }

  @media screen and (max-width: 1440px) {
    font-size: 0.9375rem;
  }

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    font-size: 0.75rem;

    h1 {
      padding: 1.5rem;
    }
  }
`;
