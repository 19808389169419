import React from "react";
import { NavLink } from "react-router-dom";
import { HeaderContainer, NavBar, Title } from "./styled";
import menu from "./assets/menu.svg";

export function Header({ setMenuIsVisible }) {
  return (
    <HeaderContainer>
      <Title>
        <h2>ABC-GO</h2>
      </Title>

      <NavBar>
        <li>
          <NavLink to="/">Home</NavLink>
        </li>
        <li>
          <NavLink to="/login">Login</NavLink>
        </li>
        <li>
          <NavLink to="/register">Cadastre-se</NavLink>
        </li>
      </NavBar>

      <img
        className="menuContent"
        src={menu}
        onClick={() => setMenuIsVisible(true)}
      />
    </HeaderContainer>
  );
}
