import { useEffect, useState } from 'react'
import Planet1 from '../../../assets/images/planeta-1.svg'
import Planet2 from '../../../assets/images/planeta-2.svg'
import Planet3 from '../../../assets/images/planeta-3.svg'
import Planet4 from '../../../assets/images/planeta-4.svg'
import Planet5 from '../../../assets/images/planeta-5.svg'
import Planet6 from '../../../assets/images/planeta-6.svg'
import Planet7 from '../../../assets/images/planeta-7.svg'


import { NavLink } from "react-router-dom";

import './chooseThePlanet.scss'

export const ChooseThePlanet = () => {
  return(
   <>
    <div className="choose-the-planets-all-planets">
      
    <div className="names-left-side">
    <div>
          <h1>PLANETA 1</h1>
          <p>Aqui você vai se divertir conhecendo as letras do alfabeto!</p>
        </div>
        <div>
          <h1 >PLANETA 3</h1>
          <p>Aqui você precisa escolher a letra que combina com som da palavra!</p>
        </div>
        <div>
          <h1 >PLANETA 5</h1>
          <p>Lorem Ipsum é simplesmente uma simulação de texto da indústria tipográfica e de impressos,
            e vem sendo utilizado desde o século XVI, quando um impressor</p>
        </div>
        <div>
          <h1 >PLANETA 7</h1>
          <p>Lorem Ipsum é simplesmente uma simulação de texto da indústria tipográfica e de impressos,
            e vem sendo utilizado desde o século XVI, quando um impressor</p>
        </div>
    </div>



      <div className="planets-container">
        <div className="line-container">
            <div className='line'></div>
        </div>
        <div className="planetas-trilha">
            <button>
                <img className="planet-img-off" alt="planeta7" src={Planet7}/>
            </button>
            <button>
                <img className="planet-img-off" alt="planeta6" src={Planet6}/>
            </button>
            <button>
                <img className="planet-img-off" alt="planeta5" src={Planet5}/>
            </button>
            <button>
                <img className="planet-img-off" alt="planeta4" src={Planet4}/>
            </button>
            <button>
                <NavLink className="link" to="/planeta3">
                  <img className="planet-img" alt="planeta3" src={Planet3}/>
                </NavLink>
            </button>
            <button>
              <NavLink className="link" to="/planeta2">
                <img  className="planet-img" alt="planeta2" src={Planet2}/>
              </NavLink> 
            </button>
            <button>

              <NavLink className="link" to="/planeta1">
                <img className="planet-img" alt="planeta1" src={Planet1}/>
              </NavLink> 
            </button>
        </div>
      </div>



      <div className="names-right-side">
        <div>
          <h1 >PLANETA 2</h1>
          <p>Aqui você deve ser capaz de identifcar qual imagem combina com o som!</p>
        </div>
        <div>
          <h1 >PLANETA 4</h1>
          <p>Lorem Ipsum é simplesmente uma simulação de texto da indústria tipográfica e de impressos,
            e vem sendo utilizado desde o século XVI, quando um impressor</p>
        </div>
        <div>
          <h1 >PLANETA 6</h1>
          <p>Lorem Ipsum é simplesmente uma simulação de texto da indústria tipográfica e de impressos,
            e vem sendo utilizado desde o século XVI, quando um impressor</p>
        </div>
      </div>
     
    </div>
   </>

  )
}