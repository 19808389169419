import { useContext } from 'react'
import { GlobalContext } from "../../../../../context/globalReducer"
import './style.scss';

export const MemoryCards = ({flipped = false, back, handleClick, id}) => {

  const [stateGame, dispatch] = useContext(GlobalContext);

  const cardContentClassNames = ['card__content'];
  flipped && cardContentClassNames.push('card__content--flipped');

  const handleClickFn = (id) => {
    if (handleClick) {
      handleClick(id);
    }
  };

  return(
    <>
    <div className="memory-game-cards-container">
    <div className="card-testezinho" onClick={() => handleClickFn(id)}>
      <div className={cardContentClassNames.join(' ')}>
        <div className="card__face card__face--front">?</div>
        <div className="card__face card__face--back">{back}</div>
      </div>
    </div>
    </div>
    </>
  )
}