import React from "react";
import { FooterContainer } from "./styled";

export const Footer = () => {
  return (
    <FooterContainer> 
        <h1>Feito por Luiza Bordini</h1>
    </FooterContainer>
  );
};
