import { SetLevel } from "../../../components/setLevel/SetLevel"
import { useContext, useState, useEffect, useMemo, useCallback } from "react";
import { SetLetter } from "../../../components/setLetter/SetLetter";
import { GlobalContext } from "../../.././context/globalReducer"
import { alphabetData } from "../../../data/data";
import './style.scss'
import React from "react";
import Joinha from '../../../assets/images/joinha.gif'
import configGameContext from "../../../context/configGameContext";
import cx from 'classnames'
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import correctSound from '../../../sounds/correct-or-wrong-sound/acerto.mp3'
import { options } from "../../../data/imagensLetras";


export const PhonemeIsolation = () => {
  useEffect(() => {
    dispatch({type:"RESTART"})
  }, [])


  function restart() {
    setContador(0)
    dispatch({type: "RESTART"})
  }

  const [imageCx, setImageCx] = useState(null)
  const [stateGame, dispatch] = useContext(GlobalContext)
  const [dataLevel, setDataLevel] = useState('');
  const [ dataLetter, setDataLetter] = useState('');
  const [ buttonNext, setButtonNext] = useState(false)
 

  var letterOfGame = alphabetData.filter(letra => letra.letter === dataLetter)
  const imageGame = letterOfGame.map(item => item.images.image1)
  const soundGame = letterOfGame.map(item => item.sound)
  const letterOfGameTeste = letterOfGame.map(item => item.letter)

function getRandomImage() {
  return alphabetData[parseInt(Math.random() * 26)].images.image1;
}

function processImages(dataLevel) {
  const levelConfig = {
    1: [imageGame],
    2: [imageGame, getRandomImage()],
    3: [imageGame, getRandomImage(), getRandomImage()],
  };

  const levelOfGame = levelConfig[dataLevel] || [imageGame];

  return levelOfGame;
}

const reoderingImages = processImages(dataLevel).sort(() => Math.random() - 0.5);

  const [contador, setContador] = useState(0)
  const winOrLose = (sound, image, index) => {
    const audio = new Audio(correctSound);
    switch (true) {
      case sound === image && dataLevel === 1:
        setContador(contador + 1);
        audio.play();
        setTimeout(() => {
          dispatch({ type: "REINFORCEMENT" });
        }, 2000);
        break;
      case sound === image && contador < 3:
        setContador(contador + 1);
        audio.play();
        dispatch({ type: "PLAY_GAME" });
        break;
      case sound === image && contador >= 3:
        setContador(0);
        setTimeout(() => {
          dispatch({ type: "REINFORCEMENT" });
        }, 2000);
        break;
      default:
        dispatch({ type: "PLAY_GAME" });
        break;
    }
  };
  
 
  function forNextLevel(){
    dispatch({type: "NEXT_LEVEL"})
    if(dataLevel <= 3){
      setDataLevel(dataLevel + 1)
    } else{
      dispatch({type: "END"})
    }
  }


  const handlePlay = (sound) => {
    const audio = new Audio(sound)
    audio.play()
  }



  return(
   <>
   <div>
    <div className="phoneme-isolation-game-container">
        <div className="game-space">
          {stateGame.gameStage === "ChooseLevel" && <configGameContext.Provider value={{dataLevel, setDataLevel}}>
                                                         <SetLevel />
                                                    </configGameContext.Provider>}
          {stateGame.gameStage === "ChooseLetter" && 
                                                    <configGameContext.Provider value={{dataLetter, setDataLetter}}>
                                                      <SetLetter />
                                                    </configGameContext.Provider>
          }
          {stateGame.gameStage === "Playing" && 
          <div>
              <div className="phoneme-isolation-elements-container">
              <button className="phoneme-isolation-restart-button"onClick={restart}><RestartAltIcon/></button>
              <div className="placar">Respostas corretas: {contador}</div>
                  <h1 className="phoneme-isolation-title">QUAL DESSAS IMAGENS COMEÇA COM O SOM: {letterOfGame.map(item => <h1>{item.audio}</h1> )}</h1>
                  <button className="phoneme-isolation-button-sound" onClick={() => handlePlay(soundGame)}> <VolumeUpIcon/></button>
                  <div className="phoneme-isolation-images-game">
                    
                  {reoderingImages.map( item => 
                      <button onClick={() => {winOrLose(item, imageGame)}}>
                        <img className={cx(`imagemgato`,{active: imageCx === true })} src={item}/>
                      </button>)}
                  </div>
                  
              </div>
          </div>}

          {stateGame.gameStage === "Reinforcement" && 
            <div className="reiforcement-for-next-level">
            <img src={Joinha}/>
            <button className="next-level-button" onClick={forNextLevel}>PRÓXIMO NÍVEL</button>
          </div>}

          {stateGame.gameStage === "NextLevel" &&
             <div>
             <div className="phoneme-isolation-elements-container">
             <button className="phoneme-isolation-restart-button"onClick={restart}><RestartAltIcon/></button>
             <div className="placar">Respostas corretas: {contador}</div>
                 <h1 className="phoneme-isolation-title">QUAL DESSAS IMAGENS COMEÇA COM O SOM: {letterOfGame.map(item => <h1>{item.audio}</h1> )}</h1>
                 <button className="phoneme-isolation-button-sound" onClick={() => handlePlay(soundGame)}> <VolumeUpIcon/></button>
                 <div className="phoneme-isolation-images-game">
                   
                 {reoderingImages.map( item => 
                     <button onClick={() => {winOrLose(item, imageGame)}}>
                       <img className={cx(`imagemgato`,{active: imageCx === true })} src={item}/>
                     </button>)}
                 </div>
                 
             </div>
         </div>}
        </div>
    </div>
    </div>
   </>
  
              
  // const alphabet = ['A', 'B', 'C', 'D', 'E'];
  // const [selectedLetter, setSelectedLetter] = useState('');
  // const [isVictory, setIsVictory] = useState(false);
  // const [wrongLetters, setWrongLetters] = useState([]);

  // const handleLetterClick = (letter) => {
  //   if (letter === selectedLetter) {
  //     setIsVictory(true);
  //   } else {
  //     if (!wrongLetters.includes(letter)) {
  //       setWrongLetters([...wrongLetters, letter]);
  //     }
  //   }
  // };

  // const startGame = () => {
  //   const randomIndex = Math.floor(Math.random() * alphabet.length);
  //   setSelectedLetter(alphabet[randomIndex]);
  //   setIsVictory(false);
  //   setWrongLetters([]);
  // };

  // const getLetterClass = (letter) => {
  //   let className = 'letter';
  //   if (wrongLetters.includes(letter)) {
  //     className += ' grayscale';
  //   }
  //   return className;
  // };

  // return (
  //   <>
  //   {stateGame.gameStage === "ChooseLevel" && <configGameContext.Provider value={{dataLevel, setDataLevel}}>
  //                                                        <SetLevel />
  //                                                   </configGameContext.Provider>}
  //         {stateGame.gameStage === "ChooseLetter" && 
  //                                                   <configGameContext.Provider value={{dataLetter, setDataLetter}}>
  //                                                     <SetLetter />
  //                                                   </configGameContext.Provider>
  //         }
  //         {stateGame.gameStage === "Playing" && 
  //             <div className="container">
  //             <h1>Letra Sorteada: {letterOfGameTeste}</h1>
  //             {alphabet.map((letter) => (
  //               <div
  //                 key={letter}
  //                 className={getLetterClass(letter)}
  //                 onClick={() => handleLetterClick(letter)}
  //               >
  //                 <img className="image-of-game" alt="imagem-game" src={letter}/>
  //                 {letter}
  //               </div>
  //             ))}
  //             {!selectedLetter && (
  //               <button className="start-button" onClick={startGame}>
  //                 Iniciar Jogo
  //               </button>
  //             )}
  //             {isVictory && <h2 className="victory-message">Parabéns! Você acertou!</h2>}
  //           </div>}
  //   </>


    )
}

export default PhonemeIsolation;


